import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import { ReactComponent as Logo } from "../images/logo.svg"
import { ReactComponent as Instagram } from "../images/instagram.svg"
import { ReactComponent as Facebook } from "../images/facebook.svg"
import { ReactComponent as Google } from "../images/google.svg"

const Header = () => (
  <header>
    <div className="logo-container">
      <Link to="/">
        <Logo />
      </Link>
    </div>
    <div className="social-container">
      <a
        href="https://www.facebook.com/konobafakin/"
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </a>
      <a
        href="https://www.instagram.com/konobafakin/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
      </a>
      <a
        href="https://www.google.com/search?hl=hr&biw=1920&bih=969&sxsrf=ALeKk03n5iJ0uHIytemul_3iBkBSmMuMKQ:1594053643827&ei=BFQDX6ejD-HGrgT-3Qc&q=restoran%20fakin%20motovun&oq=restoran+fakin+mo&gs_lcp=CgZwc3ktYWIQAxgAMgQIIxAnOgQIABBHOgIIADoFCAAQywE6BggAEBYQHlDmHlinImDkMGgAcAF4AIAB6gGIAcYEkgEFMC4yLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=psy-ab&npsic=0&rflfq=1&rlha=0&rllag=45335043,13817628,929&tbm=lcl&rldimm=1425789663118808938&lqi=ChZyZXN0b3JhbiBmYWtpbiBtb3RvdnVuWigKDnJlc3RvcmFuIGZha2luIhZyZXN0b3JhbiBmYWtpbiBtb3RvdnVu&phdesc=GoDuiKMd5mI&ved=2ahUKEwiDvMXJiLnqAhVilYsKHdiGCR0QvS4wBHoECA0QIA&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9&rlst=f#rlfi=hd:;si:1425789663118808938,l,ChZyZXN0b3JhbiBmYWtpbiBtb3RvdnVuWigKDnJlc3RvcmFuIGZha2luIhZyZXN0b3JhbiBmYWtpbiBtb3RvdnVu,y,GoDuiKMd5mI;mv:[[45.337471099999995,13.830505299999999],[45.3326151,13.8047527]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9"
        target="_blank"
        rel="noreferrer"
      >
        <Google />
      </a>
    </div>
  </header>
)

export default Header
