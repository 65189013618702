import React from "react"
import { ReactComponent as Arrow } from "../images/arrow.svg"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="footer-contact">
        <h4>Contact</h4>
        <div className="contact-container">
          <p>
            <a href="tel:0038552681598">Tel: +385 (0)52 681 598</a>
          </p>
          <p>
            <a href="tel:00385922399400">GSM: +385 (0)92 23 99 400</a>
          </p>
          <p>
            <a href="mailto:info@konobafakin.com">info@konobafakin.com</a>
          </p>
        </div>
      </div>
      <div className="find-us">
        <a
          href="https://goo.gl/maps/EZpeMAPVSXBBkiWy8"
          target="_blank"
          rel="noreferrer"
        >
          <button>
            Find us on the map <Arrow />
          </button>
        </a>
      </div>
    </footer>
  )
}

export default Footer
