import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => {
  const slika = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "pozadina.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  console.log(slika)
  return (
    <>
      <Img
        className="layout-background"
        fluid={slika.file.childImageSharp.fluid}
      />
      <div className="content">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
